import {Modal} from "bootstrap";
import Noty from 'noty';

export default function lhov() {
    document.addEventListener('livewire:init', () => {
        Livewire.on('open-modal', (event) => {
            let data = event[0];
            const openModal = document.querySelector(data['modal']);
            if (openModal) {
                const bsOpenModal = new Modal(openModal, {});
                bsOpenModal.show();
            }
        });

        Livewire.on('lhov-product-name-updated', (event) => {
            const updateModal = document.querySelector('#edit-name-product');

            if (updateModal) {
                const bsUpdateModal = Modal.getInstance(updateModal);
                bsUpdateModal.hide();
            }
        });
        Livewire.on('lhov-product-name-updated-failure', (event) => {
            const updateModal = document.querySelector('#edit-name-product');

            if (updateModal) {
                const bsUpdateModal = Modal.getInstance(updateModal);
                bsUpdateModal.hide();
            }

            let data = event[0];
            new Noty({
                type: 'error',
                text: data['error'],
            }).show();
        });

        Livewire.on('lhov-product-deleted', (event) => {
            const deleteModal = document.querySelector('#delete-product');

            if (deleteModal) {
                const bsDeleteModal = Modal.getInstance(deleteModal);
                bsDeleteModal.hide();
            }
        });
        Livewire.on('lhov-product-deleted-failure', (event) => {
            const deleteModal = document.querySelector('#delete-product');

            if (deleteModal) {
                const bsDeleteModal = Modal.getInstance(deleteModal);
                bsDeleteModal.hide();
            }

            let data = event[0];
            new Noty({
                type: 'error',
                text: data['error'],
            }).show();
        });
    });
}
